import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Index() {
  return (
    <Layout>
      <Seo title="Home" />
      <h1>Specialing in vehicle wraps and large format printing</h1>
    </Layout>
  )
}